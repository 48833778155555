import $ from 'jquery';
import whatInput from 'what-input';
import MatchHeight from 'matchheight';

window.$ = $;

//import Foundation from 'foundation-sites';
// If you don't want to pick and choose which modules to include, comment out the below and uncomment the line above
import './lib/foundation-explicit-pieces';

import '../../../node_modules/jquery.resizeend/lib/jquery.resizeend.js';

var target = window.location.hash;

if (target)
{
	target = target.replace('#', '');

	if (target)
	{
		window.scrollTo(0, 0);
		setTimeout(function ()
		{
			window.scrollTo(0, 0);
		}, 1);
	}
}

$(document).foundation();

function fix_scroll()
{
	//not sure what was used for but now it break the menu at least at maschinenbau.region-stuttgart.de so i will comment it out and see if it breaks something somewhere else
	//$('.stickyoverlap').css('top', $(window).scrollTop() + 'px');
}

//stop the menu from stick to bottom for some reason
$(window).on('sticky.zf.unstuckfrom:bottom', function (e) {
	if (Foundation.MediaQuery.atLeast('medium') || Foundation.MediaQuery.atLeast('small')) {
		$(e.target).removeClass('is-anchored is-at-bottom').attr('style', '');
	}
});

function recalculateContentPadding()
{
	var padding_v = 50;

	$('.page-content').css('padding-top', ($('.page-header').outerHeight() - padding_v));
	$('.page-content > *:first-child').css('padding-top', padding_v + 'px');
}

function update_ui()
{
	recalculateContentPadding();

	setTimeout(function ()
	{
		$('.slick').each(function (index, value)
		{
			$(this).slick('refresh');
		});
	}, 200);

	MatchHeight.update();
}

// document ready method
$(function ()
{
	var $body = $('body');
	var $menu = $('.menu');
	var $search = $('.search');
	var $searchtrigger = $('.search-trigger');
	var $menutrigger = $('.menu-trigger');

	$('.slick').slick({
		adaptiveHeight: true,
		autoplay: false
	});

	$(".fancybox").fancybox({
		toolbar: false,
		smallBtn: false,
		iframe: {
			preload: false
		}
	});

	$.expr[':'].external = function (obj)
	{
		return !obj.href.match(/^mailto\:/) && (obj.hostname != location.hostname) && !obj.href.match(/^javascript\:/) && !obj.href.match(/^$/)
	};

	$('a:external').attr('target', '_blank');

	$('a[href$=".pdf"]').attr('target', '_blank');


	$searchtrigger.click(function (e)
	{
		//console.log('search');
		e.preventDefault();
		$body.toggleClass('search-opened');
		$body.removeClass('menu-opened');
		$('.search input').focus();
	});


	$menutrigger.click(function (e)
	{
		//console.log('menu');
		e.preventDefault();
		$body.removeClass('search-opened');
		$body.toggleClass('menu-opened');
		fix_scroll();
		recalculateContentPadding();
		$('.page-header').css('max-width', '');
	});


	$('.off-canvas-wrapper').on('closed.zf.offcanvas', function ()
	{
		$('.sticky').css('top', '0px');
		$body.removeClass('menu-opened');
	});


	$(document).mouseup(function (e)
	{
		if ($body.hasClass('menu-opened') && !$menu.is(e.target) && $menu.has(e.target).length === 0 && !$menutrigger.is(e.target) && !$(e.target).closest('.menu-trigger').length)
		{
			$body.toggleClass('menu-opened');
			fix_scroll();
			recalculateContentPadding();
			$('.page-header').css('max-width', '');
		}

		if ($body.hasClass('search-opened') && !$search.is(e.target) && $search.has(e.target).length === 0 && !$searchtrigger.is(e.target) && !$(e.target).closest('.search-trigger').length)
		{
			$body.toggleClass('search-opened');
			$('.search input').blur();
		}
	});

	$('.mobile-menu .menu-item-has-children').prepend('<i class="toggler fa fa-chevron-down" aria-hidden="true"></i>');

	$(document).on('click', '.mobile-menu .menu-item-has-children .toggler', function (e)
	{
		e.preventDefault();
		e.stopPropagation();

		if ($(this).is(".fa-chevron-down"))
		{
			$(this).removeClass("fa-chevron-down");
			$(this).addClass("fa-chevron-up");
		}
		else
		{
			$(this).addClass("fa-chevron-down");
			$(this).removeClass("fa-chevron-up");
		}

		var $p = $(this).parent();

		if (!$p.hasClass('dropdown-opened'))
		{
			var h = 0;

			$p.find('> ul.sub-menu > li').each(function ()
			{
				h += $(this).outerHeight(true);
			});

			$p.find('> ul.sub-menu').css({'height': h, 'opacity': 1, 'visibility': 'visible'});
			$p.addClass('dropdown-opened');
		}
		else
		{
			$p.find('> ul.sub-menu').css({'height': 0, 'opacity': 0, 'visibility': 'hidden'});
			$p.removeClass('dropdown-opened');
		}

	});

	$("blockquote").each(function ()
	{
		$(this).has('img').addClass('quoteWithImage clearfix');
	});

	/*
	 * Onepager Smooth-Scrolling section-button
	 */
	$(".onepage-trigger a").on('click', function (event)
	{
		var root = $(this).attr('href');

		if (this.hash !== "" && window.location.href + this.hash != root)
		{
			// Prevent default anchor click behavior
			event.preventDefault();
			// Store hash
			var hash = this.hash;


			if ($(hash).length)
			{
				$('html, body').stop().animate({
					scrollTop: $(hash).offset().top - 100
				}, 800, function ()
				{
					window.location.hash = hash;

					$('html, body').stop().animate({
						scrollTop: $(hash).offset().top - $('.page-header').outerHeight()
					}, 800, function ()
					{
					});

				});
			}
			else
			{
				window.location.href = root;
			}
		}
	});


	/* ---------------------------------------------------- */
	/*	UItoTop (Back to Top)
	 /* ---------------------------------------------------- */

	(function ()
	{
		var settings = {
				button: '#back-to-top',
				text: 'Nach oben',
				min: 200,
				fadeIn: 400,
				fadeOut: 400,
				scrollSpeed: 800,
				easingType: 'swing'
			},
			oldiOS = false,
			oldAndroid = false;

		// Detect if older iOS device, which doesn't support fixed position
		if (/(iPhone|iPod|iPad)\sOS\s[0-4][_\d]+/i.test(navigator.userAgent))
		{
			oldiOS = true;
		}

		// Detect if older Android device, which doesn't support fixed position
		if (/Android\s+([0-2][\.\d]+)/i.test(navigator.userAgent))
		{
			oldAndroid = true;
		}

		$('body').append('<a href="#" id="' + settings.button.substring(1) + '" title="' + settings.text + '"><i class="fa fa-chevron-up fa-2x"></i></a>');

		$(settings.button).click(function (e)
		{
			$('html, body').stop().animate({scrollTop: 0}, settings.scrollSpeed, settings.easingType);
			e.preventDefault();
		});

		$(window).scroll(function ()
		{
			var position = $(window).scrollTop();

			if (oldiOS || oldAndroid)
			{
				$(settings.button).css({
					'position': 'absolute',
					'top': position + $(window).height()
				});
			}

			if (position > settings.min)
			{
				$(settings.button).fadeIn(settings.fadeIn);
			}
			else
			{
				$(settings.button).fadeOut(settings.fadeOut);
			}
		});

	})();

	/* end UItoTop (Back to Top) */

	recalculateContentPadding();
});

$(window).on('orientationchange', function ()
{
	update_ui();
});

$(window).on('resizeend', function ()
{
	update_ui();
});

$(window).on('load', function ()
{
	update_ui();

	setTimeout(function ()
	{
		if (target)
		{
			$('html, body').stop().animate({
				scrollTop: $('#' + target).offset().top - 100
			}, 800, 'swing', function ()
			{
			});
		}

	}, 250);
});
